import { Maybe } from 'graphql/jsutils/Maybe'
import { logger } from '@/utils/logging'
import { ProjectLinkMeta } from '../ProjectsService'
import { CatalogTitle } from './types'

export interface CatalogTitleProjectMap {
  [key: string]: ProjectLinkMeta | undefined
}

export function hasHydraIdAndSlug(title: Maybe<CatalogTitle>): boolean {
  if (!title) return false

  const hydraId = findHydraExternalId(title)
  const slug = findTitleProjectSlug(title)

  return Boolean(hydraId) && Boolean(slug)
}

export function findHydraExternalId(title: Maybe<CatalogTitle>): string | undefined {
  if (!title) return undefined

  const hydraId = title.externalIds.find((id) => id.source === 'hydra')?.id
  if (hydraId) return hydraId

  logger().error(`Content Catalog title "${title.title}" is missing a required external id with a source of "hydra"`, {
    title,
  })
}

export function findTitleProjectSlug(title?: Maybe<CatalogTitle>): string | undefined {
  if (!title) return undefined

  const slug = title?.externalIds?.find((id) => id.source === 'hydra_project_slug')?.id
  if (slug) return slug

  logger().error(
    `Content Catalog title "${title?.title}" is missing a required external id with a source of "hydra_project_slug.`,
    { title },
  )
}
