import Bluebird from 'bluebird'
import { Maybe } from 'graphql/jsutils/Maybe'
import { paths } from '@/constants'
import { Durations } from '@/constants/durations'
import {
  CatalogEpisode,
  CatalogMovie,
  CatalogSeason,
  CatalogSeries,
  CatalogSeriesCast,
  CatalogSeriesCrew,
} from '@/types/generated-content-catalog-types'
import { logger } from '@/utils/logging'
import { isDefined } from '@/utils/types'
import { cacheService } from '../CacheService'
import { Episode } from '../ProjectsService'
import { findHydraExternalId, findTitleProjectSlug } from './hydra'
import { CatalogCast, CatalogCrew, CatalogImage, CatalogTitle } from './types'

const CATALOG_API_HOST = 'https://catalog-graph-adapter.angelstudios.com'
const DEFAULT_LANGUAGE = 'en'

export async function fetchTitles(locale: string, contentIds: Maybe<Maybe<string>[]>): Promise<CatalogTitle[]> {
  if (!contentIds) return []
  const validStrings = contentIds.filter(isDefined)
  const duplicatesRemoved = Array.from(new Set(validStrings))
  let idsToFetch = duplicatesRemoved

  let titles: CatalogTitle[] = []

  const cacheKeys = idsToFetch.map((id) => getCacheKey(locale, id))
  const cachedTitles = await cacheService.mGet(cacheKeys)

  if (cachedTitles) {
    const entries = Object.entries(cachedTitles)
    idsToFetch = entries
      .filter(([_, value]) => !value)
      .map(([key]) => getIdFromCacheKey(key, locale || DEFAULT_LANGUAGE))
    titles = entries.map(([_, value]) => (value ? JSON.parse(value) : null)).filter(isDefined)
  }

  const fetchedTitles = await Bluebird.map(
    idsToFetch,
    async (contentId) => {
      const title = await fetchTitle(locale, contentId)
      if (title) return title
      else
        logger().warn(
          `Failed to fetch a content catalog item from an array of ids with id: "${contentId}" with locale: "${locale}"`,
          { contentId, locale },
        )
    },
    { concurrency: 3 },
  )

  titles = titles.concat(fetchedTitles.filter(isDefined))
  return titles
}

export async function fetchTitle<T = CatalogTitle>(locale: string, contentId: Maybe<string>): Promise<T | undefined> {
  if (!contentId) return

  try {
    const cacheKey = getCacheKey(locale, contentId)
    const cachedTitle = await cacheService.get(cacheKey)
    if (cachedTitle) {
      return JSON.parse(cachedTitle)
    }

    const res = await fetch(`${CATALOG_API_HOST}/api/v1/titles/${contentId}?internal=true`, {
      headers: {
        'x-accept-language': locale || DEFAULT_LANGUAGE,
      },
    })

    const title = res.ok ? await res.json() : undefined
    if (title) {
      await cacheService.set(cacheKey, JSON.stringify(title), { expireInSeconds: Durations.THIRTY_MINUTES_IN_SECONDS })
    }

    return title
  } catch (error) {
    logger().warn(
      `Failed to fetch content catalog title by id: "${contentId}" with locale: "${locale}"`,
      { contentId, locale },
      error,
    )
    return
  }
}

function getCacheKey(locale: string, contentId: string) {
  return cacheService.genCacheKey('contentCatalogService.fetchTitle', `${locale}-${contentId}`)
}

function getIdFromCacheKey(cacheKey: string, locale: string) {
  const parts = cacheKey.split('_')
  const last = parts[parts.length - 1]
  const id = last.replace(`${locale}-`, '')
  return id
}

export async function getWatchableTitle(
  locale: string,
  projectTitle: Maybe<CatalogTitle>,
  episode: Maybe<Episode>,
): Promise<Maybe<CatalogTitle>> {
  if (!projectTitle) return

  if (isMovie(projectTitle)) {
    return projectTitle // For a movie, the project title and the watchable title are the same object.
  }
  if (isSeries(projectTitle)) {
    return await getWatchableEpisodeTitle(locale, projectTitle, episode)
  }

  // There are other ContentCatalog Title types. Add support as needed.
  return
}

async function getWatchableEpisodeTitle(
  locale: string,
  seriesTitle: CatalogSeries,
  episode: Maybe<Episode>,
): Promise<Maybe<CatalogEpisode>> {
  if (!episode) return
  if (seriesTitle.seasonIds.length === 0) return

  const seasonNumber = episode.seasonNumber
  const seasonIndex = seasonNumber > 0 ? seasonNumber - 1 : 0
  const ccSeasonId = seriesTitle.seasonIds[seasonIndex]
  if (!ccSeasonId) return

  const seasonTitle = await fetchTitle(locale, ccSeasonId)
  if (!seasonTitle || !isSeason(seasonTitle)) return
  if (seasonTitle.episodeIds.length === 0) return

  const episodeNumber = episode.episodeNumber
  const episodeIndex = episodeNumber > 0 ? episodeNumber - 1 : 0
  const ccEpisodeId = seasonTitle.episodeIds[episodeIndex]
  if (!ccEpisodeId) return

  const episodeTitle = await fetchTitle(locale, ccEpisodeId)
  if (!episodeTitle || !isEpisode(episodeTitle)) return

  const hydraEpisodeId = findHydraExternalId(episodeTitle)
  if (hydraEpisodeId && hydraEpisodeId === episode.id) return episodeTitle
  else return
}

// DESIGN NOTE: accessors here should deal with the details of extracting
// data from the Catalog objects. Formatting beyond that should be
// separated into another module (eg, a specific date format for a page).

/**
 * Note: Current constraint: Only supports MPA in US rating
 */
export function formatTitleRating(title?: CatalogTitle): string | undefined {
  if (!title) return
  if (!(isMovie(title) || isSeries(title))) return

  return title.ratings?.find(
    (r) =>
      (isSeries(title) ? r.ratingBody === 'USA Parental Rating' : r.ratingBody === 'Motion Picture Association') &&
      r.country === 'US',
  )?.rating
}

export function formatTitleReleaseYear(title?: CatalogTitle): string | undefined {
  return !title || isSeason(title)
    ? undefined
    : title.originalReleaseDate
    ? new Date(title.originalReleaseDate).getUTCFullYear().toString()
    : undefined
}

export function formatTitleFriendlyReleaseDate(
  locale: string,
  title?: CatalogTitle,
  format: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
): string | undefined {
  return !title || isSeason(title)
    ? undefined
    : title.originalReleaseDate
    ? new Date(title?.originalReleaseDate).toLocaleDateString(locale, {
        ...format,
        timeZone: 'UTC',
      })
    : undefined
}

const VERTICAL_ASPECT_RATIOS = ['2:3', '3:4', '9:16', '1:2']
const LANDSCAPE_ASPECT_RATIOS = ['16:9', '4:3', '3:2', '2:1']

export function formatTitleMoviePosterPath(title: CatalogTitle): string | undefined {
  return findTitleMoviePoster(title)?.cloudinaryPath
}

export function findTitleMoviePoster(title: CatalogTitle): CatalogImage | undefined {
  return findImage(title, ['title_art'], VERTICAL_ASPECT_RATIOS)
}

export function formatTitleLikeVerticalImagePath(
  title: CatalogTitle,
  priorityAspects: string[] = VERTICAL_ASPECT_RATIOS,
): string | undefined {
  return findImage(
    title,
    [
      'angel_key_art_1',
      'angel_key_art_2',
      'angel_key_art_3',
      'title_art',
      'non_title_art',
      'still_01',
      'still_02',
      'still_03',
      'still_04',
      'still_05',
    ],
    priorityAspects,
  )?.cloudinaryPath
}

/**
 * Chooses from the best available images -- first by image collection, then aspect ratio.
 *
 * Image fallbacks are important because some Titles have different image data populated
 */
function findImage(title: CatalogTitle, priorityKeys: string[], priorityAspects: string[]): CatalogImage | undefined {
  return priorityKeys.reduce((found: CatalogImage | undefined, key: string) => {
    if (found) return found
    return title && findImageByAspect(title.images[key], priorityAspects)
  }, undefined)

  function findImageByAspect(images: CatalogImage[], priorityAspects: string[]): CatalogImage | undefined {
    const aspectsMap = mapImageAspects(images)
    const bestAspect = priorityAspects.find((aspect) => aspect in aspectsMap)
    return bestAspect ? aspectsMap[bestAspect] : undefined
  }
}

export function formatTitleLikeLandscapeImagePath(
  title: CatalogTitle,
  priorityAspects: string[] = LANDSCAPE_ASPECT_RATIOS,
): string | undefined {
  return findImage(
    title,
    [
      'angel_key_art_1',
      'angel_key_art_2',
      'angel_key_art_3',
      'title_art',
      'non_title_art',
      'still_1',
      'still_2',
      'still_3',
      'still_4',
      'still_5',
    ],
    priorityAspects,
  )?.cloudinaryPath
}

export function formatNonTitleLikeLandscapeImagePath(
  title: CatalogTitle,
  priorityAspects: string[] = LANDSCAPE_ASPECT_RATIOS,
): string | undefined {
  return findImage(
    title,
    ['backdrop', 'non_title_art', 'still_1', 'still_2', 'still_3', 'still_4', 'still_5'],
    priorityAspects,
  )?.cloudinaryPath
}

export function formatNonTitleLikeVerticalImagePath(
  title: CatalogTitle,
  priorityAspects: string[] = VERTICAL_ASPECT_RATIOS,
): string | undefined {
  return findImage(
    title,
    ['backdrop', 'non_title_art', 'still_1', 'still_2', 'still_3', 'still_4', 'still_5'],
    priorityAspects,
  )?.cloudinaryPath
}

export function formatTitleLogo(title: CatalogTitle | undefined): CatalogImage | undefined {
  if (!title) return

  const aspects = mapImageAspects(title.images.logo)
  const bestAspect = ['9:5', '2:1', '1:1'].find((aspect) => aspect in aspects)
  return bestAspect ? aspects[bestAspect] : undefined
}

export function formatDimensions([maxWidth, maxHeight]: [number | 'auto', number | 'auto'], image: CatalogImage) {
  if (maxWidth === 'auto' && maxHeight === 'auto') return

  const [x, y] = image.aspect.split(':').map(Number)

  const initialWidth = maxHeight !== 'auto' ? calcWidth(x, y, maxHeight as number) : maxWidth
  const initialHeight = maxWidth !== 'auto' ? calcHeight(x, y, maxWidth as number) : maxHeight

  const finalWidth = maxWidth !== 'auto' && (initialWidth as number) > maxWidth ? maxWidth : initialWidth
  const finalHeight = maxHeight !== 'auto' && (initialHeight as number) > maxHeight ? maxHeight : initialHeight

  return {
    width: Math.round(finalWidth as number),
    height: Math.round(finalHeight as number),
  }

  function calcWidth(x: number, y: number, height: number) {
    return (x * height) / y
  }

  function calcHeight(x: number, y: number, width: number) {
    return (y * width) / x
  }
}

export function isSpecial(title: CatalogTitle): title is CatalogEpisode {
  return title.type === 'special'
}

export function isEpisode(title: CatalogTitle): title is CatalogEpisode {
  return title.type === 'episode'
}

export function isSeason(title: CatalogTitle): title is CatalogSeason {
  return title.type === 'season'
}

export function isMovie(title: CatalogTitle): title is CatalogMovie {
  return title.type === 'movie'
}

export function isSeries(title: CatalogTitle): title is CatalogSeries {
  return title.type === 'series'
}

export function isTheatrical(title: CatalogTitle): title is CatalogMovie {
  return title.type === 'theatrical'
}

export function isSeriesCast(cast: CatalogCast): cast is CatalogSeriesCast {
  return 'characters' in cast
}

export function isSeriesCrew(crew: CatalogCrew): crew is CatalogSeriesCrew {
  return 'jobs' in crew
}

export function formatTitleDuration(title: CatalogTitle): string | undefined {
  if (!isMovie(title)) return
  if (title.duration === 0 || !title.duration) return

  let seconds = title.duration
  let hours = ''
  if (seconds > Durations.ONE_HOUR_IN_SECONDS) {
    hours = Math.floor(seconds / Durations.ONE_HOUR_IN_SECONDS) + 'H'
    seconds = seconds % Durations.ONE_HOUR_IN_SECONDS
  }

  const minutes = Math.round(seconds / 60.0) + 'M'

  return `${hours}${minutes}`
}

export interface TitleCrewMap {
  'Executive Producer': string[]
  'Director of Photography': string[]
  Producer: string[]
  Director: string[]
  Writer: string[]
  Distributor: string[]
}
const TITLE_CREW_MATCHERS: { [key in keyof TitleCrewMap]: (s: string) => boolean } = {
  'Executive Producer': (s: string) => /.*executive producer.*/i.test(s),
  'Director of Photography': (s: string) => /.*director of photography.*/i.test(s),
  Producer: (s: string) => /.*producer.*/i.test(s) && !/.*executive producer.*/i.test(s),
  Director: (s: string) => /.*director.*/i.test(s) && !/.*director of photography.*/i.test(s),
  Writer: (s: string) => /.*writer.*/i.test(s),
  Distributor: (s: string) => /.*distributor.*/i.test(s),
}
export function formatTitleCrewMap(title: CatalogTitle): TitleCrewMap {
  if (isSeries(title)) {
    return title.crew.reduce(
      (acc, crew) => {
        return Object.keys(TITLE_CREW_MATCHERS).reduce((acc, key) => {
          return (crew.jobs || []).some((job) => TITLE_CREW_MATCHERS[key as keyof typeof TITLE_CREW_MATCHERS](job))
            ? {
                ...acc,
                [key]: [...acc[key as keyof typeof acc], crew.name],
              }
            : acc
        }, acc)
      },
      {
        'Executive Producer': [],
        'Director of Photography': [],
        Producer: [],
        Director: [],
        Writer: [],
        Distributor: [],
      },
    )
  } else if (isMovie(title)) {
    return title.crew.reduce(
      (acc, crew) => {
        return Object.keys(TITLE_CREW_MATCHERS).reduce((acc, key) => {
          return TITLE_CREW_MATCHERS[key as keyof typeof TITLE_CREW_MATCHERS](crew.job)
            ? {
                ...acc,
                [key]: [...acc[key as keyof typeof acc], crew.name],
              }
            : acc
        }, acc)
      },
      {
        'Executive Producer': [],
        'Director of Photography': [],
        Producer: [],
        Director: [],
        Writer: [],
        Distributor: [],
      },
    )
  } else {
    logger().error('In formatTitleCrewMap, title.type for this title is not supported.', { title })
    return {
      'Executive Producer': [],
      'Director of Photography': [],
      Producer: [],
      Director: [],
      Writer: [],
      Distributor: [],
    }
  }
}

export function filterTitleDirector(title?: CatalogTitle): CatalogCrew[] {
  return filterCrewByJob(TITLE_CREW_MATCHERS.Director, title)
}

export function filterTitleProducer(title?: CatalogTitle): CatalogCrew[] {
  return filterCrewByJob(TITLE_CREW_MATCHERS.Producer, title)
}

function filterCrewByJob(matcher: (job: string) => boolean, title?: CatalogTitle): CatalogCrew[] {
  if (!title) return []

  return isSeries(title)
    ? title.crew.filter((crew) => crew.jobs.some(matcher))
    : isMovie(title)
    ? title.crew.filter((crew) => matcher(crew.job))
    : []
}

export function formatTitleTitleArtImagePath(title: CatalogTitle): string | undefined {
  const aspects = mapImageAspects(title.images.title_art)
  const bestAspect = ['2:1', '16:9', '3:2', '4:3'].find((aspect) => aspect in aspects)
  return bestAspect ? aspects[bestAspect].cloudinaryPath : undefined
}

export function isTitleCastEmpty(title: CatalogTitle | undefined) {
  return !title || !(isMovie(title) || isSeries(title)) || !Array.isArray(title.cast) || title.cast.length === 0
}

export function formatCastHeadshotPath(cast: CatalogCast): string | undefined {
  const aspects = mapImageAspects(cast.images.headshot)
  const bestAspect = ['1:1'].find((aspect) => aspect in aspects)
  return bestAspect ? aspects[bestAspect].cloudinaryPath : undefined
}

export interface CatalogTitleMap {
  [key: string]: CatalogTitle
}

/**
 * Arranges a collection of titles into a direct access-by-id map.
 */
export function formatTitleMap(titles: CatalogTitle[]): CatalogTitleMap {
  return titles.reduce((acc, title) => {
    acc[title.id] = title
    return acc
  }, {} as CatalogTitleMap)
}

/**
 * Determines if this title's watch CTA destination is the 'episode' watch page or not
 */
export function isTitleLinkedToEpisodeWatchPage(title: CatalogTitle) {
  return isEpisode(title) || isSpecial(title)
}

export function formatWatchPath(title: CatalogTitle): string | undefined {
  const slug = findTitleProjectSlug(title)
  if (!slug) return

  const hydraId = findHydraExternalId(title)
  return hydraId && isTitleLinkedToEpisodeWatchPage(title)
    ? `${paths.watch.index}/${slug}/episode/${hydraId}`
    : `${paths.watch.index}/${slug}`
}

function mapImageAspects(arr: CatalogImage[] | undefined): Record<string, CatalogImage> {
  return mapObject(
    (obj) => obj.aspect,
    (obj) => obj,
    arr,
  )
}

function mapObject<T, V>(key: (obj: T) => string, value: (obj: T) => V, arr: T[] | undefined): Record<string, V> {
  if (!arr) return {}
  return arr.reduce((acc, obj) => {
    acc[key(obj)] = value(obj)
    return acc
  }, {} as Record<string, V>)
}

export { findTitleProjectSlug }
