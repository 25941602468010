import { logger } from './logging'

export function getRequiredEnvVar(envVar: string): string {
  const v = process.env[envVar]
  if (typeof v === 'string') return v

  throw new Error(`A required env var is missing: "${envVar}"`)
}

export function getRequiredEnvVarAsInt(envVar: string): number {
  const v = getRequiredEnvVar(envVar)
  const i = parseInt(v)
  if (isNaN(i)) {
    throw new Error(`Expected the env var "${envVar}" to be an integer but got: "${v}"`)
  }
  return i
}

export function getOptionalEnvVar(envVar: string): string | undefined {
  const v = process.env[envVar]
  if (typeof v === 'string') return v

  logger().warn(`An optional env var is missing: "${envVar}"`)
}
