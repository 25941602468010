/* eslint-disable*/
// To parse this data:
//
//   import { Convert, CatalogSeries, CatalogSeason, CatalogEpisode, CatalogMovie, CatalogTheatrical } from "./file";
//
//   const catalogSeries = Convert.toCatalogSeries(json);
//   const catalogSeason = Convert.toCatalogSeason(json);
//   const catalogEpisode = Convert.toCatalogEpisode(json);
//   const catalogMovie = Convert.toCatalogMovie(json);
//   const catalogTheatrical = Convert.toCatalogTheatrical(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface CatalogSeries {
  cast: CatalogSeriesCast[]
  copyright: string
  countryOfOrigin: string
  crew: CatalogSeriesCrew[]
  deeplinks: CatalogSeriesDeeplink[]
  description: CatalogSeriesDescription
  externalIds: CatalogSeriesExternalID[]
  genres: string[]
  id: string
  images: { [key: string]: CatalogSeriesImage[] }
  originalReleaseDate: Date
  ratings: CatalogSeriesRating[]
  seasonIds: string[]
  title: string
  type: string
  isInEarlyAccess?: boolean
}

export interface CatalogSeriesCast {
  characters: string[]
  images: { [key: string]: CatalogSeriesImage[] }
  name: string
}

export interface CatalogSeriesImage {
  aspect: string
  category: string
  cloudinaryPath: string
}

export interface CatalogSeriesCrew {
  images: { [key: string]: CatalogSeriesImage[] }
  jobs: string[]
  name: string
}

export interface CatalogSeriesDeeplink {
  platform: string
  url: string
}

export interface CatalogSeriesDescription {
  long: string
  medium: string
  short: string
}

export interface CatalogSeriesExternalID {
  id: string
  source: string
}

export interface CatalogSeriesRating {
  country: string
  rating: string
  ratingBody: string
}

export interface CatalogSeason {
  deeplinks: CatalogSeasonDeeplink[]
  description: CatalogSeasonDescription
  episodeIds: string[]
  externalIds: CatalogSeasonExternalID[]
  id: string
  images: { [key: string]: CatalogSeasonImage[] }
  number: number
  seriesId: string
  title: string
  type: string
}

export interface CatalogSeasonDeeplink {
  platform: string
  url: string
}

export interface CatalogSeasonDescription {
  long: string
  medium: string
  short: string
}

export interface CatalogSeasonExternalID {
  id: string
  source: string
}

export interface CatalogSeasonImage {
  aspect: string
  category: string
  cloudinaryPath: string
}

export interface CatalogEpisode {
  advisories: CatalogEpisodeAdvisory[]
  cast: CatalogEpisodeCast[]
  copyright: string
  countryOfOrigin: string
  crew: CatalogEpisodeCrew[]
  deeplinks: CatalogEpisodeDeeplink[]
  description: CatalogEpisodeDescription
  /**
   * Duration in seconds
   */
  duration: number
  externalIds: CatalogEpisodeExternalID[]
  genres: string[]
  id: string
  images: { [key: string]: CatalogEpisodeImage[] }
  number: number
  originalReleaseDate: Date
  ratings: CatalogEpisodeRating[]
  seasonId: string
  title: string
  type: string
  isInEarlyAccess?: boolean
}

export interface CatalogEpisodeAdvisory {
  code: string
  label: string
}

export interface CatalogEpisodeCast {
  character: string
  images: { [key: string]: CatalogEpisodeImage[] }
  name: string
}

export interface CatalogEpisodeImage {
  aspect: string
  category: string
  cloudinaryPath: string
}

export interface CatalogEpisodeCrew {
  department: string
  images: { [key: string]: CatalogEpisodeImage[] }
  job: string
  name: string
}

export interface CatalogEpisodeDeeplink {
  platform: string
  url: string
}

export interface CatalogEpisodeDescription {
  long: string
  medium: string
  short: string
}

export interface CatalogEpisodeExternalID {
  id: string
  source: string
}

export interface CatalogEpisodeRating {
  country: string
  rating: string
  ratingBody: string
}

export interface CatalogMovie {
  advisories: CatalogMovieAdvisory[]
  cast: CatalogMovieCast[]
  copyright: string
  countryOfOrigin: string
  crew: CatalogMovieCrew[]
  deeplinks: CatalogMovieDeeplink[]
  description: CatalogMovieDescription
  /**
   * Duration in seconds
   */
  duration: number
  externalIds: CatalogMovieExternalID[]
  genres: string[]
  id: string
  images: { [key: string]: CatalogMovieImage[] }
  originalReleaseDate: Date
  ratings: CatalogMovieRating[]
  title: string
  type: string
  isInEarlyAccess?: boolean
}

export interface CatalogMovieAdvisory {
  code: string
  label: string
}

export interface CatalogMovieCast {
  character: string
  images: { [key: string]: CatalogMovieImage[] }
  name: string
}

export interface CatalogMovieImage {
  aspect: string
  category: string
  cloudinaryPath: string
}

export interface CatalogMovieCrew {
  department: string
  images: { [key: string]: CatalogMovieImage[] }
  job: string
  name: string
}

export interface CatalogMovieDeeplink {
  platform: string
  url: string
}

export interface CatalogMovieDescription {
  long: string
  medium: string
  short: string
}

export interface CatalogMovieExternalID {
  id: string
  source: string
}

export interface CatalogMovieRating {
  country: string
  rating: string
  ratingBody: string
}

export interface CatalogTheatrical {
  advisories: CatalogTheatricalAdvisory[]
  cast: CatalogTheatricalCast[]
  copyright: string
  countryOfOrigin: string
  crew: CatalogTheatricalCrew[]
  deeplinks: CatalogTheatricalDeeplink[]
  description: CatalogTheatricalDescription
  /**
   * Duration in seconds
   */
  duration: number
  externalIds: CatalogTheatricalExternalID[]
  genres: string[]
  id: string
  images: { [key: string]: CatalogTheatricalImage[] }
  originalReleaseDate: Date
  ratings: CatalogTheatricalRating[]
  title: string
  type: string
}

export interface CatalogTheatricalAdvisory {
  code: string
  label: string
}

export interface CatalogTheatricalCast {
  character: string
  images: { [key: string]: CatalogTheatricalImage[] }
  name: string
}

export interface CatalogTheatricalImage {
  aspect: string
  category: string
  cloudinaryPath: string
}

export interface CatalogTheatricalCrew {
  department: string
  images: { [key: string]: CatalogTheatricalImage[] }
  job: string
  name: string
}

export interface CatalogTheatricalDeeplink {
  platform: string
  url: string
}

export interface CatalogTheatricalDescription {
  long: string
  medium: string
  short: string
}

export interface CatalogTheatricalExternalID {
  id: string
  source: string
}

export interface CatalogTheatricalRating {
  country: string
  rating: string
  ratingBody: string
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toCatalogSeries(json: string): CatalogSeries {
    return cast(JSON.parse(json), r('CatalogSeries'))
  }

  public static catalogSeriesToJson(value: CatalogSeries): string {
    return JSON.stringify(uncast(value, r('CatalogSeries')), null, 2)
  }

  public static toCatalogSeason(json: string): CatalogSeason {
    return cast(JSON.parse(json), r('CatalogSeason'))
  }

  public static catalogSeasonToJson(value: CatalogSeason): string {
    return JSON.stringify(uncast(value, r('CatalogSeason')), null, 2)
  }

  public static toCatalogEpisode(json: string): CatalogEpisode {
    return cast(JSON.parse(json), r('CatalogEpisode'))
  }

  public static catalogEpisodeToJson(value: CatalogEpisode): string {
    return JSON.stringify(uncast(value, r('CatalogEpisode')), null, 2)
  }

  public static toCatalogMovie(json: string): CatalogMovie {
    return cast(JSON.parse(json), r('CatalogMovie'))
  }

  public static catalogMovieToJson(value: CatalogMovie): string {
    return JSON.stringify(uncast(value, r('CatalogMovie')), null, 2)
  }

  public static toCatalogTheatrical(json: string): CatalogTheatrical {
    return cast(JSON.parse(json), r('CatalogTheatrical'))
  }

  public static catalogTheatricalToJson(value: CatalogTheatrical): string {
    return JSON.stringify(uncast(value, r('CatalogTheatrical')), null, 2)
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
  const prettyTyp = prettyTypeName(typ)
  const parentText = parent ? ` on ${parent}` : ''
  const keyText = key ? ` for key "${key}"` : ''
  throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`)
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`
    } else {
      return `one of [${typ
        .map((a) => {
          return prettyTypeName(a)
        })
        .join(', ')}]`
    }
  } else if (typeof typ === 'object' && typ.literal !== undefined) {
    return typ.literal
  } else {
    return typeof typ
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }))
    typ.jsonToJS = map
  }
  return typ.jsonToJS
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }))
    typ.jsToJSON = map
  }
  return typ.jsToJSON
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val
    return invalidValue(typ, val, key, parent)
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length
    for (let i = 0; i < l; i++) {
      const typ = typs[i]
      try {
        return transform(val, typ, getProps)
      } catch (_) {}
    }
    return invalidValue(typs, val, key, parent)
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val
    return invalidValue(
      cases.map((a) => {
        return l(a)
      }),
      val,
      key,
      parent,
    )
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l('array'), val, key, parent)
    return val.map((el) => transform(el, typ, getProps))
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null
    }
    const d = new Date(val)
    if (isNaN(d.valueOf())) {
      return invalidValue(l('Date'), val, key, parent)
    }
    return d
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue(l(ref || 'object'), val, key, parent)
    }
    const result: any = {}
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key]
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined
      result[prop.key] = transform(v, prop.typ, getProps, key, ref)
    })
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref)
      }
    })
    return result
  }

  if (typ === 'any') return val
  if (typ === null) {
    if (val === null) return val
    return invalidValue(typ, val, key, parent)
  }
  if (typ === false) return invalidValue(typ, val, key, parent)
  let ref: any = undefined
  while (typeof typ === 'object' && typ.ref !== undefined) {
    ref = typ.ref
    typ = typeMap[typ.ref]
  }
  if (Array.isArray(typ)) return transformEnum(typ, val)
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers')
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems')
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty('props')
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val, key, parent)
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(val)
  return transformPrimitive(typ, val)
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps)
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps)
}

function l(typ: any) {
  return { literal: typ }
}

function a(typ: any) {
  return { arrayItems: typ }
}

function u(...typs: any[]) {
  return { unionMembers: typs }
}

function o(props: any[], additional: any) {
  return { props, additional }
}

function m(additional: any) {
  return { props: [], additional }
}

function r(name: string) {
  return { ref: name }
}

const typeMap: any = {
  CatalogSeries: o(
    [
      { json: 'cast', js: 'cast', typ: a(r('CatalogSeriesCast')) },
      { json: 'copyright', js: 'copyright', typ: '' },
      { json: 'countryOfOrigin', js: 'countryOfOrigin', typ: '' },
      { json: 'crew', js: 'crew', typ: a(r('CatalogSeriesCrew')) },
      { json: 'deeplinks', js: 'deeplinks', typ: a(r('CatalogSeriesDeeplink')) },
      { json: 'description', js: 'description', typ: r('CatalogSeriesDescription') },
      { json: 'externalIds', js: 'externalIds', typ: a(r('CatalogSeriesExternalID')) },
      { json: 'genres', js: 'genres', typ: a('') },
      { json: 'id', js: 'id', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogSeriesImage'))) },
      { json: 'originalReleaseDate', js: 'originalReleaseDate', typ: Date },
      { json: 'ratings', js: 'ratings', typ: a(r('CatalogSeriesRating')) },
      { json: 'seasonIds', js: 'seasonIds', typ: a('') },
      { json: 'title', js: 'title', typ: '' },
      { json: 'type', js: 'type', typ: '' },
    ],
    false,
  ),
  CatalogSeriesCast: o(
    [
      { json: 'characters', js: 'characters', typ: a('') },
      { json: 'images', js: 'images', typ: m(a(r('CatalogSeriesImage'))) },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogSeriesImage: o(
    [
      { json: 'aspect', js: 'aspect', typ: '' },
      { json: 'category', js: 'category', typ: '' },
      { json: 'cloudinaryPath', js: 'cloudinaryPath', typ: '' },
    ],
    false,
  ),
  CatalogSeriesCrew: o(
    [
      { json: 'images', js: 'images', typ: m(a(r('CatalogSeriesImage'))) },
      { json: 'jobs', js: 'jobs', typ: a('') },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogSeriesDeeplink: o(
    [
      { json: 'platform', js: 'platform', typ: '' },
      { json: 'url', js: 'url', typ: '' },
    ],
    false,
  ),
  CatalogSeriesDescription: o(
    [
      { json: 'long', js: 'long', typ: '' },
      { json: 'medium', js: 'medium', typ: '' },
      { json: 'short', js: 'short', typ: '' },
    ],
    false,
  ),
  CatalogSeriesExternalID: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'source', js: 'source', typ: '' },
    ],
    false,
  ),
  CatalogSeriesRating: o(
    [
      { json: 'country', js: 'country', typ: '' },
      { json: 'rating', js: 'rating', typ: '' },
      { json: 'ratingBody', js: 'ratingBody', typ: '' },
    ],
    false,
  ),
  CatalogSeason: o(
    [
      { json: 'deeplinks', js: 'deeplinks', typ: a(r('CatalogSeasonDeeplink')) },
      { json: 'description', js: 'description', typ: r('CatalogSeasonDescription') },
      { json: 'episodeIds', js: 'episodeIds', typ: a('') },
      { json: 'externalIds', js: 'externalIds', typ: a(r('CatalogSeasonExternalID')) },
      { json: 'id', js: 'id', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogSeasonImage'))) },
      { json: 'number', js: 'number', typ: 0 },
      { json: 'seriesId', js: 'seriesId', typ: '' },
      { json: 'title', js: 'title', typ: '' },
      { json: 'type', js: 'type', typ: '' },
    ],
    false,
  ),
  CatalogSeasonDeeplink: o(
    [
      { json: 'platform', js: 'platform', typ: '' },
      { json: 'url', js: 'url', typ: '' },
    ],
    false,
  ),
  CatalogSeasonDescription: o(
    [
      { json: 'long', js: 'long', typ: '' },
      { json: 'medium', js: 'medium', typ: '' },
      { json: 'short', js: 'short', typ: '' },
    ],
    false,
  ),
  CatalogSeasonExternalID: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'source', js: 'source', typ: '' },
    ],
    false,
  ),
  CatalogSeasonImage: o(
    [
      { json: 'aspect', js: 'aspect', typ: '' },
      { json: 'category', js: 'category', typ: '' },
      { json: 'cloudinaryPath', js: 'cloudinaryPath', typ: '' },
    ],
    false,
  ),
  CatalogEpisode: o(
    [
      { json: 'advisories', js: 'advisories', typ: a(r('CatalogEpisodeAdvisory')) },
      { json: 'cast', js: 'cast', typ: a(r('CatalogEpisodeCast')) },
      { json: 'copyright', js: 'copyright', typ: '' },
      { json: 'countryOfOrigin', js: 'countryOfOrigin', typ: '' },
      { json: 'crew', js: 'crew', typ: a(r('CatalogEpisodeCrew')) },
      { json: 'deeplinks', js: 'deeplinks', typ: a(r('CatalogEpisodeDeeplink')) },
      { json: 'description', js: 'description', typ: r('CatalogEpisodeDescription') },
      { json: 'duration', js: 'duration', typ: 0 },
      { json: 'externalIds', js: 'externalIds', typ: a(r('CatalogEpisodeExternalID')) },
      { json: 'genres', js: 'genres', typ: a('') },
      { json: 'id', js: 'id', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogEpisodeImage'))) },
      { json: 'number', js: 'number', typ: 0 },
      { json: 'originalReleaseDate', js: 'originalReleaseDate', typ: Date },
      { json: 'ratings', js: 'ratings', typ: a(r('CatalogEpisodeRating')) },
      { json: 'seasonId', js: 'seasonId', typ: '' },
      { json: 'title', js: 'title', typ: '' },
      { json: 'type', js: 'type', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeAdvisory: o(
    [
      { json: 'code', js: 'code', typ: '' },
      { json: 'label', js: 'label', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeCast: o(
    [
      { json: 'character', js: 'character', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogEpisodeImage'))) },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeImage: o(
    [
      { json: 'aspect', js: 'aspect', typ: '' },
      { json: 'category', js: 'category', typ: '' },
      { json: 'cloudinaryPath', js: 'cloudinaryPath', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeCrew: o(
    [
      { json: 'department', js: 'department', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogEpisodeImage'))) },
      { json: 'job', js: 'job', typ: '' },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeDeeplink: o(
    [
      { json: 'platform', js: 'platform', typ: '' },
      { json: 'url', js: 'url', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeDescription: o(
    [
      { json: 'long', js: 'long', typ: '' },
      { json: 'medium', js: 'medium', typ: '' },
      { json: 'short', js: 'short', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeExternalID: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'source', js: 'source', typ: '' },
    ],
    false,
  ),
  CatalogEpisodeRating: o(
    [
      { json: 'country', js: 'country', typ: '' },
      { json: 'rating', js: 'rating', typ: '' },
      { json: 'ratingBody', js: 'ratingBody', typ: '' },
    ],
    false,
  ),
  CatalogMovie: o(
    [
      { json: 'advisories', js: 'advisories', typ: a(r('CatalogMovieAdvisory')) },
      { json: 'cast', js: 'cast', typ: a(r('CatalogMovieCast')) },
      { json: 'copyright', js: 'copyright', typ: '' },
      { json: 'countryOfOrigin', js: 'countryOfOrigin', typ: '' },
      { json: 'crew', js: 'crew', typ: a(r('CatalogMovieCrew')) },
      { json: 'deeplinks', js: 'deeplinks', typ: a(r('CatalogMovieDeeplink')) },
      { json: 'description', js: 'description', typ: r('CatalogMovieDescription') },
      { json: 'duration', js: 'duration', typ: 0 },
      { json: 'externalIds', js: 'externalIds', typ: a(r('CatalogMovieExternalID')) },
      { json: 'genres', js: 'genres', typ: a('') },
      { json: 'id', js: 'id', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogMovieImage'))) },
      { json: 'originalReleaseDate', js: 'originalReleaseDate', typ: Date },
      { json: 'ratings', js: 'ratings', typ: a(r('CatalogMovieRating')) },
      { json: 'title', js: 'title', typ: '' },
      { json: 'type', js: 'type', typ: '' },
    ],
    false,
  ),
  CatalogMovieAdvisory: o(
    [
      { json: 'code', js: 'code', typ: '' },
      { json: 'label', js: 'label', typ: '' },
    ],
    false,
  ),
  CatalogMovieCast: o(
    [
      { json: 'character', js: 'character', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogMovieImage'))) },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogMovieImage: o(
    [
      { json: 'aspect', js: 'aspect', typ: '' },
      { json: 'category', js: 'category', typ: '' },
      { json: 'cloudinaryPath', js: 'cloudinaryPath', typ: '' },
    ],
    false,
  ),
  CatalogMovieCrew: o(
    [
      { json: 'department', js: 'department', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogMovieImage'))) },
      { json: 'job', js: 'job', typ: '' },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogMovieDeeplink: o(
    [
      { json: 'platform', js: 'platform', typ: '' },
      { json: 'url', js: 'url', typ: '' },
    ],
    false,
  ),
  CatalogMovieDescription: o(
    [
      { json: 'long', js: 'long', typ: '' },
      { json: 'medium', js: 'medium', typ: '' },
      { json: 'short', js: 'short', typ: '' },
    ],
    false,
  ),
  CatalogMovieExternalID: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'source', js: 'source', typ: '' },
    ],
    false,
  ),
  CatalogMovieRating: o(
    [
      { json: 'country', js: 'country', typ: '' },
      { json: 'rating', js: 'rating', typ: '' },
      { json: 'ratingBody', js: 'ratingBody', typ: '' },
    ],
    false,
  ),
  CatalogTheatrical: o(
    [
      { json: 'advisories', js: 'advisories', typ: a(r('CatalogTheatricalAdvisory')) },
      { json: 'cast', js: 'cast', typ: a(r('CatalogTheatricalCast')) },
      { json: 'copyright', js: 'copyright', typ: '' },
      { json: 'countryOfOrigin', js: 'countryOfOrigin', typ: '' },
      { json: 'crew', js: 'crew', typ: a(r('CatalogTheatricalCrew')) },
      { json: 'deeplinks', js: 'deeplinks', typ: a(r('CatalogTheatricalDeeplink')) },
      { json: 'description', js: 'description', typ: r('CatalogTheatricalDescription') },
      { json: 'duration', js: 'duration', typ: 0 },
      { json: 'externalIds', js: 'externalIds', typ: a(r('CatalogTheatricalExternalID')) },
      { json: 'genres', js: 'genres', typ: a('') },
      { json: 'id', js: 'id', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogTheatricalImage'))) },
      { json: 'originalReleaseDate', js: 'originalReleaseDate', typ: Date },
      { json: 'ratings', js: 'ratings', typ: a(r('CatalogTheatricalRating')) },
      { json: 'title', js: 'title', typ: '' },
      { json: 'type', js: 'type', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalAdvisory: o(
    [
      { json: 'code', js: 'code', typ: '' },
      { json: 'label', js: 'label', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalCast: o(
    [
      { json: 'character', js: 'character', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogTheatricalImage'))) },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalImage: o(
    [
      { json: 'aspect', js: 'aspect', typ: '' },
      { json: 'category', js: 'category', typ: '' },
      { json: 'cloudinaryPath', js: 'cloudinaryPath', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalCrew: o(
    [
      { json: 'department', js: 'department', typ: '' },
      { json: 'images', js: 'images', typ: m(a(r('CatalogTheatricalImage'))) },
      { json: 'job', js: 'job', typ: '' },
      { json: 'name', js: 'name', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalDeeplink: o(
    [
      { json: 'platform', js: 'platform', typ: '' },
      { json: 'url', js: 'url', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalDescription: o(
    [
      { json: 'long', js: 'long', typ: '' },
      { json: 'medium', js: 'medium', typ: '' },
      { json: 'short', js: 'short', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalExternalID: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'source', js: 'source', typ: '' },
    ],
    false,
  ),
  CatalogTheatricalRating: o(
    [
      { json: 'country', js: 'country', typ: '' },
      { json: 'rating', js: 'rating', typ: '' },
      { json: 'ratingBody', js: 'ratingBody', typ: '' },
    ],
    false,
  ),
}
