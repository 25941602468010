import axios from 'axios'
import { getAngelEnvironment } from '@/utils/environment-utils'
import { getRequiredEnvVar } from '@/utils/environment-variables'
import { logger } from '@/utils/logging'

export type CacheKey = `${string}_${string}_${string}`

export interface CacheOptions {
  expireInSeconds?: number
}

async function get(key: CacheKey): Promise<string | null> {
  try {
    const baseUrl = getRequiredEnvVar('NEXT_PUBLIC_BFF_API_URL')
    const response = await axios.get(`${baseUrl}/cache`, { params: { key } })
    return response.data.value
  } catch (err) {
    logger().error('Unexpected error in cacheService.get', { key, err })
    return null
  }
}

async function mGet(keys: CacheKey[]): Promise<Record<CacheKey, string | null>> {
  try {
    const baseUrl = getRequiredEnvVar('NEXT_PUBLIC_BFF_API_URL')
    const response = await axios.post(`${baseUrl}/cache/multi/get`, { keys })
    return response.data.keyValueMap
  } catch (err) {
    logger().error('Unexpected error in cacheService.mGet', { keys, err })
    return {}
  }
}

async function set(key: CacheKey, value: string, options?: CacheOptions): Promise<void> {
  try {
    const baseUrl = getRequiredEnvVar('NEXT_PUBLIC_BFF_API_URL')
    await axios.post(`${baseUrl}/cache`, { key, value, options })
  } catch (err) {
    logger().error('Unexpected error in cacheService.set', { key, value, options, err })
  }
}

async function mSet(keyValueMap: Record<CacheKey, string>, options?: CacheOptions): Promise<void> {
  try {
    const baseUrl = getRequiredEnvVar('NEXT_PUBLIC_BFF_API_URL')
    await axios.post(`${baseUrl}/cache/multi/set`, { keyValueMap, options })
  } catch (err) {
    logger().error('Unexpected error in cacheService.mSet', { keyValueMap, err })
  }
}

export function genCacheKey(moduleName: string, descriptor: string): CacheKey {
  const potentialKey: CacheKey = `${getAngelEnvironment()}_${moduleName}_${descriptor}`
  const hasInvalidChars = potentialKey.match(/[^a-zA-Z0-9._-]/)
  if (hasInvalidChars) {
    throw new Error(`Invalid characters used in cache key: ${potentialKey}`)
  }
  if (potentialKey.length > 255) {
    throw new Error(`Cache key is too long, the key must be <256 characters: ${potentialKey}`)
  }
  return potentialKey
}

export const cacheService = { genCacheKey, get, mGet, set, mSet }
